import React                    from "react";
import {Pages}                  from "./Pages";
import ReactDOM                 from "react-dom";
import {EntitiesSearchDropList} from "../components/EntitiesSearchDropList";
import {Entities}               from "../enums/Entities";

export class Calcs extends Pages {
    constructor(params) {
        super(params);

    }

    initPageHandlers(params) {
        super.initPageHandlers(params);

        ReactDOM.render(
            <EntitiesSearchDropList
                placeholder={"быстрый поиск по ID группы или начисления, году или номеру месяца"}
                entity={Entities.CALCS}/>,
            document.getElementById('calcs-search-form'),
        );

    }
}
